import React from "react";
import { Layout, SEO, BlueOrangeDivider } from '../components/structure';
import { Hero, ImageContent, IconBlock, CallToAction, Button, Content, Testimonial } from "../components/blocks"
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { brand, mq, font } from "../styles";
import GatsbyImage from "gatsby-image";
import { Helmet } from "react-helmet"
import CountUp from 'react-countup'
import IconsList from "../components/blocks/icons/Icons";

import english from "../../content/translations/en-CA/home.json"
import french from "../../content/translations/fr-CA/home.json"



const IndexPage = () => {
  let lang = english;
  
  const pageQuery = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-Homepage.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      heroUpArrow: file(base: {eq: "homepage-arrow.png"}){
        childImageSharp {
          fixed(width: 660, height: 680, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      mobileHeroUpArrow: file(base: {eq: "homepage-arrow.png"}){
        childImageSharp {
          fixed(width: 150, height: 125, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
        }
      }



      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      rightArrowIconOrange: file(base: {eq: "rightArrowOrange.png"}) {
        ...ButtonIcon
      }
      rightArrowIconBlue: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      heroIcon: file(base: {eq: "prem_upgrade_icon.png"}) {
        ...HeroIcon
      }
      secondaryHeroIcon: file(base: {eq: "prem_upgrade_icon_orange.png"}) {
        ...secondaryHeroIcon
      }
      contentSectionOneIcon: file(base: {eq:"PGIcon.png"}) {
        ...ContentSideIcon
      }
      contentSectionOneIconBlockOne: file(base: {eq:"PGIcon.png"}) {
        ...ContentBlockIcon
      }
      contentSectionOneGrapic: file(base: {eq:"HomeGraphic_01.png"}) {
        childImageSharp {
          fixed(width: 330, height: 550, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      contentSectionTwoGrapic: file(base: {eq:"HomeGraphic_01.png"}) {
        childImageSharp {
          fixed(width: 330, height: 450, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      CTABackgroundImage: file(base: {eq:"CTATestBG.png"}){
        ...CTABackground
      }
      partnerLogoOne: file(base: {eq:"home_lufthansa.png"}) {
        ...PartnerLogo
      }
      partnerLogoTwo: file(base: {eq:"EtihadGold.png"}) {
        ...PartnerLogo
      }
      partnerLogoThree: file(base: {eq:"Home_MarriottBlack.png"}) {
        ...PartnerLogo
      }
      partnerLogoFour: file(base: {eq:"Home_Canada.png"}) {
        ...PartnerLogo
      }
      partnerLogoFive: file(base: {eq:"IHG-NewLogo.png"}) {
        ...PartnerLogo
      }
      partnerLogoSix: file(base: {eq:"Home_Hilton.jpeg"}) {
        ...PartnerLogo
      }
      partnerLogoSeven: file(base: {eq:"RoyalCaribbean.png"}) {
        ...PartnerLogo
      }
      partnerLogoEight: file(base: {eq:"home_amatrak_blue.png"}) {
        ...PartnerLogo
      }
      partnerLogoNine: file(base: {eq:"Home_SouthwestAirlines.png"}) {
        ...PartnerLogo
      }
      partnerLogoTen: file(base: {eq:"Norwegian_Cruises_inline.png"}) {
        ...PartnerLogo
      }
      partnerLogoEleven: file(base: {eq:"Qantas.png"}) {
        ...PartnerLogo
      }
      partnerLogoTwelve: file(base: {eq:"Home_CitiBank.jpg"}) {
        ...PartnerLogo
      }
      testimonialsBG: file(base: { eq: "HomeTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconOne: file(base: { eq: "certified.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconTwo: file(base: { eq: "revenueOpp.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconThree: file(base: { eq: "noEntry.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconFour: file(base: { eq: "bullseye.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconFive: file(base: { eq: "approved.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FirstIconSix: file(base: { eq: "loyalty-circles.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      plusIconOrange: file(base: {eq: "plus-orange.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      loyaltyCirclesBlue: file(base: {eq: "loyaltyCirclesBlue.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      learnMoreBG: file(base: { eq: "HomeProducts.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

    }
  `);

  const CounterContainer = styled.div`
    width: 100%;
    .inner-counters-container {
      width: 100%;
      max-width: 1400px;
      background-color: #fff;
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 40px 0;
      @media only screen and ${mq.maxMd} {
        display: inline-block;
      }
    }
  `;

  const LogoPreviewContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    max-width: 1400px;
    margin: 20px auto 80px;
    background-color: #fff;
    padding: 0 0;
    
    .logo-preview-header {
      display: block;
      width: 95%;
      max-width: 800px;
      margin: auto;
      text-align: center;

      h4 {
        font-family: ${font.family.sherika};
        font-weight: 600;
        font-size: 56px;
        color: ${brand.colors.pg_blue};
        @media only screen and ${mq.maxMd} {
          font-size: 30px;
        }
      }
      p {
        font-family: ${font.family.sherika};
        font-weight: 400;
        font-size: 20px;
        color: ${brand.colors.pg_primary_dark};
      }
    }

    .logo-preview-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px 20px;
      flex-wrap: wrap;
      text-align: center;
      margin: 40px 0;
      @media only screen and ${mq.maxMd} {
        justify-content: center;
      }
      @media only screen and (max-width: 600px) {
        gap: 0;
        flex-direction: column;
        align-items: center;
      }

      .logo-wrapper {
        width: 300px;

        @media only screen and (max-width: 600px) {
          display: flex;
          height: 150px;
          width: 250px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .button-con {
      width: 95%;
      margin: auto;
      text-align: center;
    }
  `;

  const BackgroundSection = styled.div`
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 1100px;
    margin: auto;
  `;

  const HomepageHero = styled.div`
  
  ${ font.imports.sherika }
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
    min-height: 550px;

    @media only screen and ${mq.maxMd} {
      height: auto;
    }

    .background-image-con {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }
    }

    .hero-content-con {
      position: relative;
      z-index: 450;
      width: 95%;
      max-width: 1400px;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      @media only screen and ${mq.maxMd} {
        display: block;
      }
      .hero-content-copy {
        flex-basis: 50%;
        height: 70%;
        display: flex;
        flex-direction: column;
        @media only screen and ${mq.maxMd} {
          display: block;
          height: auto;
          padding-top: 130px;
          margin-bottom: -100px;
          position: relative;
          z-index: 1000;
        }
        @media only screen and (max-width: 1325px) {
          top: 15%;
        }
        .copy-overflow {
          .hero-content-headline {
            color: #fff;
            font-family: ${font.family.sherika};
            font-weight: 600;
            font-size: 75px;
            margin-bottom: 40px;
            @media only screen and (max-width: 1325px) {
              font-size: 55px;
            }
            @media screen and ${mq.maxMd} {
              font-size: 40px;
            text-align: center;
            }
            @media screen and ${mq.maxSm} {
              font-size: 30px;
            }
          }
          .hero-content-para {
            color: #fff;
            font-family: ${font.family.sherika};
            font-weight: 600;
            font-size: 28px;
            line-height: 30px;
            width: 75%;
            @media only screen and (max-width: 1325px) {
              font-size: 25px;
            }
            @media only screen and ${mq.maxMd} {
              font-size: 20px;
    text-align: center;
    width: 100%;
            }
            
          }

          .button-con {
            display: flex;
            flex-direction: row;
            gap: 0 30px;
            width: 75%;
            @media only screen and (max-width: 1170px) {
              width: 100%;
            }
            @media only screen and ${mq.maxMd} {
              display: block;
              text-align: center;
              &.hero{
              span{
                width: 75%;
                margin-bottom: 10px;
              }
            }
            }
          }
        }
        
      }

      .hero-graphic-con {
        width: 660px;
        height: 850px;
        @media only screen and ${mq.maxMd} {
          max-height: 430px;
          max-width: 380px;
          width: auto;
          margin: auto;
        }
        .gatsby-image-wrapper {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }


    
  `;

const FirstChoiceCon = styled.div`

${ font.imports.sherika }

    width: 100%;
    padding: 80px 0;

    h2 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 56px;
      text-align: center;
      color: ${brand.colors.pg_orange};
      @media only screen and ${mq.maxMd} {
        font-size: 40px;
      }
    }

    .inner-container {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      width: 95%;
      max-width: 1400px;
      margin: 60px auto 0;
      @media only screen and ${mq.maxMd} {
        display: block;
      }
      .icon-detail-con {
        flex-basis: 30%;
        text-align: center;
        padding: 20px;
        p {
          font-family: ${font.family.sherika};
          font-weight: 700;
          font-size: 26px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and (max-width: 480px) {
            font-size: 23px;
          }
        }
      }
    }

`;

const LearnMoreCon =  styled.div`

${ font.imports.sherika }

position: relative;
width: 100%;
height: auto;
min-height: 800px;

.background-image-con {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: grayscale(1) contrast(1.1);

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 350;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.7231267507002801) 10%, rgba(0,0,0,0.5508578431372548) 49%, rgba(0,0,0,0) 100%);
  }
}

.learn-more-content {
  position: relative;
  height: 100%;
  width: 95%;
  margin: auto;
  padding-top: 80px;

  h2 {
    text-align: left;
    font-family: ${font.family.sherika};
    font-weight: 600;
    font-size: 56px;
    max-width: 800px;
    margin: auto;
    margin-bottom: 40px;
    @media only screen and ${mq.maxMd} {
      font-size: 40px;
      text-align: center;
    }
    @media only screen and ${mq.maxSm}{
      font-size: 30px;
    }
  }

  .learn-more-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    max-width: 1140px;
    margin: auto;
    @media only screen and ${mq.maxMd} {
      display:block;
      text-align: center;
    }
    .learn-more-section {
      flex-basis: 45%;
      @media only screen and ${mq.maxMd} {
        padding-bottom: 40px;
      }
      h4 {
        font-family: ${font.family.sherika};
        font-weight: 600;
        font-size: 32px;
        color: ${brand.colors.pg_primary_light};
      }

      p{
        font-family: ${font.family.sherika};
        font-weight: 400;
        font-size: 20px;
        color: ${brand.colors.pg_primary_light};
        margin-bottom: 70px;
        padding: 10px;
        border: 1px solid;
        border-left: none;
        border-right: none;
      }
    }

    .ancillary {
      p {
        border-color: ${brand.colors.pg_orange};
      }
    }

    .loyalty {
      p {
        border-color: ${brand.colors.pg_blue};
      }
    }
  }
  
}

`;
  return(
    <Layout>
      <Helmet>
        <meta name="facebook-domain-verification" content="o7hzmqrmnsqg5ackjr8bb7u3v4t47y" />
        <meta name="p:domain_verify" content="c0396a6ed2c931fbe91dbb38cebe02a3"/>
      </Helmet>
      <SEO
        title="Plusgrade | The Global Ancillary Revenue Powerhouse"
        description={"Plusgrade powers the global travel industry with its portfolio of leading ancillary revenue solutions."}
        keywords={'travel, ancillary revenue, upgrades'}
        lang="fr"
      />
      <HomepageHero>
        <div className="background-image-con">
            <GatsbyImage fixed={pageQuery['heroImg'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "top center"}} />
            
        
        </div>
        <div className="hero-content-con">
          <div className="hero-content-copy">
            <div className="copy-overflow">
              <h1 className="hero-content-headline">
                Ancillary revenue products your customers will love
              </h1>
              <p className="hero-content-para">
                Over 200 companies worldwide trust Plusgrade’s ancillary offerings and loyalty expertise to create incredible travel experiences and new revenue opportunities.
              </p>
              <div className="button-con hero">
                <Button destination={'/contact/request-a-demo/'} label='See a demo' icon={pageQuery['rightArrowIconWhite']} orange whiteText pill />
                <Button destination={'/contact/'} label='Contact sales' icon={pageQuery['rightArrowIconWhite']} blue whiteText pill />
              </div>
              
            </div>
          </div>
          <div className="hero-graphic-con">
            {/* <GatsbyImage fixed={pageQuery['heroUpArrow'].childImageSharp.fixed} imgStyle={{objectFit: "contain", objectPosition: "bottom center"}}/> */}
          
            <GatsbyImage className={'aboutBg'} fixed={[pageQuery['mobileHeroUpArrow'].childImageSharp.fixed,
        {
          ...pageQuery.heroUpArrow.childImageSharp.fixed,
          media: `(min-width: 991px)`,
        },
      ]}
      imgStyle={{objectFit: "contain", objectPosition: "bottom center"}} />
          </div>
        </div>
      </HomepageHero>
      <Content whiteBG>
        <CounterContainer>
          <div className={"inner-counters-container"}>
              <StatsCard stat={200} decimal={0} prefix={""} suffix={"+"}>
                  travel partners
              </StatsCard>
              <StatsCard stat={60} decimal={0} prefix={""} suffix={""}>
                countries
              </StatsCard>
              <StatsCard stat={5} decimal={0} prefix={"$"} suffix={"B+"}>
              in new revenue opportunities in 2023
              </StatsCard>
              <StatsCard stat={5} decimal={0} prefix={""} suffix={""}>
              industries
              </StatsCard>
              <StatsCard stat={25} decimal={0} prefix={""} suffix={"M+"}>
                incredible customer experiences in 2023
              </StatsCard>
            </div>
        </CounterContainer>
      

      <LogoPreviewContainer>
        <div className="logo-preview-header">
          <h4>See the innovators in ancillary revenue</h4>
          <p>We are powering amazing customer experiences for incredible companies worldwide.</p>
        </div>
        <div className="logo-preview-con">
          
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoOne'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoTwo'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoThree'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoFour'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoTwelve'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoSix'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoSeven'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoEight'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoNine'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoTen'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoEleven'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
          <div className={"logo-wrapper"}>
            <GatsbyImage fixed={pageQuery['partnerLogoFive'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} />
          </div>
        </div>

        <div className="button-con">
          <Button destination={'/partners/'} label={"See all partners"} icon={pageQuery['rightArrowIconWhite']} blue whiteText pill/>
        </div>
      </LogoPreviewContainer>

      <Testimonial indexPage
          testimonials={
            [
              "Our partnership with Plusgrade has over-delivered on customer engagement and continues to deliver meaningful ancillary revenue.--Manager, Ancillary Revenue, Qantas",
              "Dynamic Seat Blocker is the exact innovation we were looking for to ensure that our guests fly confidently. However passengers want to fly, with someone sitting next to them or not, Seat Blocker gives them the power to decide.--Umesh Chhiber, Senior Vice President - Revenue Optimization & Pricing, Oman Air"
            ]
          }
          background={pageQuery['testimonialsBG']}
          imageStyle = {{objectPosition: "center center"}}
          title ={"What our partners say"}
        />

      <FirstChoiceCon>
        <h2>Why we’re the first choice</h2>
        <div className="inner-container">
          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconOne'].childImageSharp.fixed} />
            </div>
            <p>{"Quick integration &\nwhite-label solutions"}</p>
          </div>

          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconTwo'].childImageSharp.fixed} />
            </div>
            <p>{"Direct\nrevenue stream"}</p>
          </div>

          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconThree'].childImageSharp.fixed} />
            </div>
            <p>{"Zero-risk &\nno upfront costs"}</p>
          </div>

          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconFour'].childImageSharp.fixed} />
            </div>
            <p>{"Intelligent targeting &\ndecision engines"}</p>
          </div>

          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconFive'].childImageSharp.fixed} />
            </div>
            <p>{"Proven year-over-year\nrevenue growth"}</p>
          </div>

          <div className="icon-detail-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['FirstIconSix'].childImageSharp.fixed} />
            </div>
            <p>{"Loyalty program\nintegration"}</p>
          </div>
        </div>
      </FirstChoiceCon>

      <LearnMoreCon>
        <div className="background-image-con">
          <GatsbyImage fixed={pageQuery['learnMoreBG'].childImageSharp.fixed} imgStyle={{objectPosition: "top center"}}/>
        </div>
        <div className="learn-more-content">
          <h2>Plusgrade brings together the products that spark joy in the traveler journey.</h2>
          <div className="learn-more-inner">
            <div className="learn-more-section ancillary">
              <div className="learn-more-icon">
                <GatsbyImage fixed={pageQuery['plusIconOrange'].childImageSharp.fixed} />
              </div>
              <h4 className="headline">Ancillary Revenue Experiences</h4>
              <p className="copy">The leading portfolio of ancillary revenue solutions for the global travel industry. We focus on generating significant and high-margin revenue enhancements and high-impact customer experiences.<br/></p>

              <Button destination={'/products/'} label={'Learn more'} white orangeText pill />
            </div>

            <div className="learn-more-section loyalty">
              <div className="learn-more-icon">
                <GatsbyImage fixed={pageQuery['loyaltyCirclesBlue'].childImageSharp.fixed} />
              </div>
              <h4 className="headline">Loyalty by Points</h4>
              <p className="copy">Points, a Plusgrade company, helps make loyalty program currencies more useful for members, giving them innovative ways to get, move, and keep their points and miles while driving revenue for travel companies.</p>

              <Button destination={'https://www.points.com'} label={'Learn more'} faIcon={IconsList.externalBlue} rel={'noreferer'} target={'_blank'} white blueText pill external/>

            </div>
          </div>
        </div>
      </LearnMoreCon>
      

      <CallToAction responsiveButton
          title={'Drive ancillary revenue\nwith incredible customer experiences'}
          subtitle={'Contact us today to learn more.'}
          > 
          <Button destination={'/contact/request-a-demo'} label={'Request a demo'} icon={pageQuery['rightArrowIconBlue']} white blueText pill />
        </CallToAction>
    </Content>
          
        
    </Layout>
  );
}

export default IndexPage;

const StatsCardElement = styled.div`
    text-align: center;
    padding: 30px 20px;
    position: relative;
    flex-basis: 100%;
    margin-bottom: 15px;
    color: ${brand.colors.pg_primary_dark};
    font-size: 25px;
    font-weight: 600;
    align-items: center;
    @media screen and ${mq.maxMd} {
      padding: 10px;
    }
    
    > div {
      width: 100%;
      font-size: 20px;
      font-family: ${font.family.sherika};
      font-weight: 700;
      text-align: center;
      @media only screen and (max-width: 1130px) {
        font-size: 17px;
      }
    }

    @media screen and ${mq.minMd} {
      flex-basis: 24%;
      margin-bottom: 0;
      display: inline-block;
    }
    @media screen and ${mq.minSm} {
      flex-basis: 23%;
      
    }

    span {
      font-family: ${font.family.sherika};
      font-size: 88px;
      line-height: 1;
      font-weight: 900;
      left: 0;
      right: 0;
      top: 0;
      color: ${brand.colors.pg_orange};
      margin-bottom: 20px;
      display: block;
      @media only screen and (max-width: 1130px) {
        font-size: 48px;
      }
      @media screen and ${mq.maxMd} {
        margin-bottom: 10px;
      }
    }
    }
  `;

const StatsCard = ({ stat, children, decimal, prefix, suffix, ...props }) => (
  <StatsCardElement {...props}>
    <span><CountUp end={stat} enableScrollSpy={true} scrollSpyOnce={true} duration={3} decimals={decimal} prefix={prefix} suffix={suffix} useEasing={true} preserveValue={true}/></span>
    <div>{ children }</div>
  </StatsCardElement>
);

export const query = graphql`
  fragment PartnerLogo on File {
    childImageSharp {
      fixed(width: 260,  webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  `;


